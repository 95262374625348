<template>
    <div class="left">
        <div class="flex align-items-center gap-10" style="min-width: 135px;">
            <img class="img-profile" :src="require(`@/assets/avatares/patients/${obj.avatar}.png`)" alt="" />
            <Status :status="obj.status" />
            <b class="name">{{ obj.name }}</b>

            <div class="flex align-items-center gap-12">
                <Dependent :obj="obj" />
                <HealthRecord :obj="obj" />
                <MakeAppointment :obj="obj" />
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from "vue";
import Status from "./Status.vue";
import Dependent from "./Dependent";
import HealthRecord from "./HealthRecord";
import MakeAppointment from "./MakeAppointment";

export default {
    props: ["obj"],
    components: {
        Status,
        Dependent,
        HealthRecord,
        MakeAppointment,
    },
    setup() {

        return {

        }
    }
}
</script>

<style lang="scss" scoped>
h1 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #BDBDBD;
    margin: 0;
    padding: 0;
}

b {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #4F4F4F;
}

.left {
    display: flex;
    align-items: center;
    width: max-content;
    gap: 32px;

    .name{
        min-width: 140px;
    }

    .img-profile {
        width: 40px;
        height: 40px;
        border-radius: 10px;
    }
}
</style>