export const dataRelatives = {
    group: 'Familiares',
    data: [
        {
            avatar: "8",
            name: "Murillo Ribeiro",
            status: "aprovado",
            dependent: true,
            health_record: false,
            make_appointment: false,
        },
    ]
}

export const dataFriends = {
    group: 'Amigos',
    data: [
        {
            avatar: "3",
            name: "Gabrielly Pleoneri",
            status: "aprovado",
            dependent: true,
            health_record: true,
            make_appointment: true,
        },
        {
            avatar: "7",
            name: "Marcos Santos",
            status: "aprovado",
            dependent: false,
            health_record: true,
            make_appointment: true,
        },
        // {
        //     avatar: "15",
        //     name: "Filipe",
        //     status: "rejeitado",
        //     dependent: false,
        //     health_record: false,
        //     make_appointment: false,
        // },
    ]
}