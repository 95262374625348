<template>
    <div class="right">
        <div class="view-solicitations cursor-pointer">Ver solicitações</div>
        <div class="flex align-items-center gap-16">
            <img class="pointer" src="../../../../../../assets/icons/remove-item.png" alt="remove item" />
        </div>
    </div>
</template>

<script>
import { ref } from "vue";


export default {
    props: ["obj"],
    components:{

    },    
    setup() {
        

        return {

        }
    }
}
</script>

<style lang="scss" scoped>
h1 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #BDBDBD;
    margin: 0;
    padding: 0;
}
b{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #4F4F4F;
}
.pointer{
    cursor: pointer;
}

.right {
    display: flex;
    align-items: center;
    width: max-content;
    gap: 18px;
    padding-right: 20px;
    .img-profile{
        width: 40px;
        height: 40px;
        border-radius: 10px;
        margin-right: 12px;
    }
    .view-solicitations{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 14px;
        height: 26px;
        background: #2F80ED;
        border-radius: 100px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #FFFFFF;
    }
}
</style>