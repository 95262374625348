import avatarRequest1 from "../../../../assets/avatares/patients/5.png";
import avatarRequest2 from "../../../../assets/avatares/patients/11.png";

export const dataRequest = {
    group: 'Familiares',
    data: [
        {
            avatar: avatarRequest1,
            name: "Beatriz Jobim",
            sex: "F",
            status: "solicitado",
            dependent: {
                status: true,
                hidden: false,
                dependents: [
                    {
                        date: "22/06/2022",
                        exam: "Ginecologia geral rotineira",
                        professional: "Doutora Mariza Medeiros",
                    },
                    {
                        date: "14/05/2022",
                        exam: "Exames locais ortopédicos",
                        professional: "Doutor Carlos Quintana",
                    },
                ]
            },
            status_service: "registro",
            action: "marcar"
        },
        {
            avatar: avatarRequest2,
            name: "Carlos Bongra Junior",
            sex: "M",
            status: "solicitado",
            dependent: {
                status: true,
                hidden: false,
                dependents: [
                    {
                        date: "22/06/2022",
                        exam: "Exames locais ortopédicos",
                        professional: "Doutor Carlos Quintana",
                    },
                    {
                        date: "20/06/2022",
                        exam: "Exames gerais de rotina",
                        professional: "Doutor Marcos Gomes",
                    },
                ]
            },
            status_service: "registro",
            action: "marcar"
        },
    ]
}

export const data = [
    {
        name: "Marcos Rosa",
        informations: ["Financeiro", "Compromissos", "Registros"],
        avatar: "1"
    },
    {
        name: "Ana Cristina",
        informations: ["Compromissos", "Registros"],
        avatar: "5"
    },
    {
        name: "Antonia Rosa",
        informations: ["Compromissos", "Registros"],
        avatar: "6"
    },
]