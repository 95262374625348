<template>
    <div class="status">
        <img src="../../../../../../assets/icons/approved.png" alt="status aprovado" v-if="status === 'aprovado'" />
        <img src="../../../../../../assets/icons/solicited.png" alt="status solicitado" v-else-if="status === 'solicitado'" />
        <img src="../../../../../../assets/icons/reject-permission.png" alt="status rejeitado" v-else-if="status === 'rejeitado'" />
    </div>
</template>

<script>
import { ref } from "vue";

export default {
    props: ["status"],
    setup() {
        const getStatus = status => {
            return String(status)
        }

        function upperCase(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }

        return {
            getStatus,
            upperCase,
        }
    }
}
</script>

<style lang="scss" scoped>
h1 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #BDBDBD;
    margin: 0;
    padding: 0;
}

b {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #4F4F4F;
}

.status {
    display: flex;
    align-items: center;
    width: max-content;

    .aprovado{
        color: #6FCF97;
    }
    .solicitado{
        color: #F2994A;
    }
}
</style>