<template>
    <div 
        class="dependent" 
        :class="obj.dependent ? 'enable' : 'disabled'"
        v-if="obj.dependent"
    >
        <img src="../../../../../assets/icons/financial-dependent.png" alt="" />
        Dependência financeira
        <img class="cursor-pointer" :src="require(`@/assets/icons/${obj.dependent}.png`)" alt="">
    </div>
</template>

<script>
import { ref } from "vue";

export default {
    props: ["obj", "visibleItem"],
    setup() {


        return {
        }
    }
}
</script>

<style lang="scss" scoped>
p {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
}

.dependent {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: max-content;
    gap: 8px;
    min-width: 103px;

    .marcar {
        color: #FF6A33;
    }

    .editar {
        color: #828282;
    }
}

.enable {
    color: #828282;
    border: 1px solid #2D9CDB;
    gap: 8px;
    padding: 2px 14px;
    border-radius: 100px;
}

.disabled {
    color: #BDBDBD;
    border: 1px solid #BDBDBD;
    gap: 12px;
    padding: 2px 14px;
    border-radius: 100px;
    cursor: not-allowed;
}
.arrow-disable{
    filter: invert(75%) sepia(0%) saturate(0%) hue-rotate(346deg) brightness(101%) contrast(93%);
}
</style>